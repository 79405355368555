import * as React from "react"
import Logo from "../../../images/logo.png"
import * as HeaderStyles from "./Header.module.css"

export default function Header() {
    return (
        <header>
            <img className={HeaderStyles.logo} src={Logo} width="100%" alt="Perfection logo" />
        </header>
    )
}