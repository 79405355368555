import * as React from "react"
import * as FooterStyles from "./Footer.module.css"

const date = new Date()
const year = date.getFullYear()

export default function Footer() {
    return (
        <footer>
            <div className={FooterStyles.container}>
                <p className={FooterStyles.credit}>Website by <a href="https://goddard.digital" target="_blank">Goddard Digital</a></p>
                <p className={FooterStyles.copyright}>{year} © - Perfection Valet</p>
            </div>
        </footer>
    )
}