import * as React from "react"
import * as Styles from "./index.module.css"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/Layout"
import TxtImg from "../components/TxtImg/TxtImg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "@fontsource/spectral"
import "@fontsource/raleway"
import MetaImage from "../images/pv-rich.jpg"

export default function Home({ data }) {
  const audi = getImage(data.audi)
  const rich = getImage(data.rich)

  const Testimonial = props => (
    <div className={Styles.testimonial}>
      <blockquote>"{props.quote}"</blockquote>
      <div>
        <p className={Styles.client}>{props.client}</p>
        <p className={Styles.company}>{props.company}</p>
      </div>
    </div>
  )

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Perfection Valet - Independent Automotive Detailers</title>
        <meta name="description" content="Perfection Valet is a professional, conscientious automotive detailing service that has been responsible for looking after the world's finest automobiles for over 30 years." />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.perfectiondetailing.co.uk/" />
        <meta name="twitter:title" content="Perfection Valet - Independent Automotive Detailers" />
        <meta name="twitter:description" content="Perfection Valet is a professional, conscientious automotive detailing service that has been responsible for looking after the world's finest automobiles for over 30 years." />
        <meta name="twitter:image" content={`https://www.perfectiondetailing.co.uk${MetaImage}`} />
      </Helmet>
      <main>
        <section className={Styles.introSection}>
          <TxtImg split="half" imgFirst={true} imgSrc={audi} imgAlt="Audi">
            <h1><span className={Styles.highlight}>Perfection Valet</span> has been responsible for looking after the world's finest automobiles for over 30 years.</h1>
            <p>During this time, Richard Tipper has built up an unrivalled portfolio of some of the most significant cars on the planet and it's been said that he has worked on more cars in his career than anyone else in the detailing industry.</p>
          </TxtImg>
        </section>
        <section className={Styles.servicesSection}>
          <div className={Styles.customShapeDividerTop1622628025}>
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M1200 0L0 0 892.25 114.72 1200 0z" className={Styles.shapeFill}></path>
            </svg>
          </div>
          <div className={Styles.servicesContainer}>
          <h2>Services</h2>
            <div className={Styles.servicesGrid}>
              <p>Paint Correction</p>
              <p>Fine Finishing</p>
              <p>Concours Preparation</p>
              <p>Show Support</p>
              <p>Corporate Events</p>
              <p>Training & Advice</p>
              <p>Vehicle Commissions</p>
            </div>
          </div>
        </section>
        <section className={Styles.twitterSection}>
          <p>Find Perfection Valet on Twitter:</p>
          <a href="https://twitter.com/perfectionvalet" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} />@perfectionvalet</a>
        </section>
        <section className={Styles.testimonialSection}>
          <div className={Styles.testimonials}>
            <Testimonial
              quote="We always rely on Richard to really bring a car alive. He can revive paintwork and his attention to detail is always excellent. We'd recommend him to anyone and is the only person we trust with our high value inventory."
              client="Gregor Fisken"
              company="Fiskens Fine Historic Automobiles, London."
            />
            <Testimonial
              quote="The result was an absurdly clean Porsche. In bright sunshine it looks wet, the inky black paint displaying a positively satanic shine."
              client="Richard Meaden"
              company="964 RS, evo magazine issue 100."
            />
          </div>
        </section>
        <section className={Styles.contactSection}>
          <div className={Styles.contactContainer}>
            <div className={Styles.imgContainer}>
              <div className={Styles.imgWrapper}>
                <GatsbyImage image={rich} alt="Richard Tipper at work" />
              </div>
            </div>
            <div className={Styles.formContainer}>
            <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" action="/success">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <h3>Contact Richard</h3>
                <p>Call on <a href="tel:+447860336825">07860 33 68 25</a> or send a message below.</p>
                <label>Name<br/>
                  <input type="text" placeholder="Enter your name" name="name" required />
                </label>
                <label>Email<br/>
                  <input type="email" placeholder="Enter your email address" name="email" required />
                </label>
                <label>Message<br/>
                  <textarea placeholder="Enter your message" name="message" rows="5" required></textarea>
                </label>
                <input type="submit" value="Send message" />
              </form>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    audi: file(relativePath: {eq: "pv-audi.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
    rich: file(relativePath: {eq: "pv-rich.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 1500)
      }
    }
  }
`