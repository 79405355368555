// extracted by mini-css-extract-plugin
export var highlight = "index-module--highlight--iofGd";
export var introSection = "index-module--introSection--_DmSW";
export var customShapeDividerTop1622628025 = "index-module--customShapeDividerTop1622628025--2f0DB";
export var shapeFill = "index-module--shapeFill--3rxeN";
export var servicesSection = "index-module--servicesSection--DWIUD";
export var servicesContainer = "index-module--servicesContainer--2L7S6";
export var servicesGrid = "index-module--servicesGrid--2i-QV";
export var twitterSection = "index-module--twitterSection--Ds_oO";
export var testimonialSection = "index-module--testimonialSection--3YQHO";
export var testimonials = "index-module--testimonials--3E3qo";
export var testimonial = "index-module--testimonial--3jroH";
export var client = "index-module--client--2h0mt";
export var company = "index-module--company--Dz2_F";
export var contactSection = "index-module--contactSection--3FlEY";
export var contactContainer = "index-module--contactContainer--38d9h";
export var formContainer = "index-module--formContainer--QM2Wq";
export var imgContainer = "index-module--imgContainer--12dBl";
export var imgWrapper = "index-module--imgWrapper--nhYjz";